import React, { Component } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Input } from "reactstrap";

class Contact extends Component {
  state = {
    name: "",
    message: "",
    email: "",
    sent: false,
    buttonText: "Send message"
  };

  render() {
    return (
      <div className="container">
        <div className="col-md-4 justify-content-center offset-md-4">
          <h2>Contact</h2>
          <Form className="contact-form" onSubmit={e => this.formSubmit(e)}>
            <FormGroup>
              <Input
                onChange={e => this.setState({ name: e.target.value })}
                name="name"
                className="message-name"
                type="text"
                placeholder="What's your name?"
                value={this.state.name}
              />
            </FormGroup>
            <FormGroup>
              <Input
                onChange={e => this.setState({ email: e.target.value })}
                name="email"
                className="message-email"
                type="email"
                placeholder="your@email.com"
                required
                value={this.state.email}
              />
            </FormGroup>
            <FormGroup>
              <Input
                onChange={e => this.setState({ message: e.target.value })}
                name="message"
                className="message-input"
                type="textarea"
                placeholder="Say hi!"
                required
                value={this.state.message}
              />
            </FormGroup>
            <div className="button--container">
              <Button type="submit" className="button button-primary">
                {this.state.buttonText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  formSubmit = e => {
    e.preventDefault();

    this.setState({
      buttonText: "...sending"
    });

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    };

    axios
      .post("https://scc-api-contact.now.sh/", data)
      .then(res => {
        this.setState({ sent: true }, this.resetForm());
      })
      .catch(() => {
        console.log("Message was not sent");
      });
  };

  resetForm = () => {
    this.setState({
      name: "",
      message: "",
      email: "",
      buttonText: "Message sent!"
    });
  };
}

export default Contact;
