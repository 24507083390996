import React from "react";
import PortfolioForm from "./components/portfolioForm";
import PortfolioView from "./components/portfolioView";

export default () => {
  console.log("Signed in as admin");
  return (
    <div class="container">
      <PortfolioForm /> <PortfolioView />
    </div>
  );
};
