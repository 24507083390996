import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { storage } from "../index";
import axios from "axios";

const Portfolio = props => (
  <tr>
    <td>
      <img
        className="portfolioLogoList"
        src={props.portfolio.img}
        alt="Portfolio logo"
      />
      <br />
      {props.portfolio.name}
    </td>
    <td>
      {" "}
      <a href={props.portfolio.url}>{props.portfolio.url}</a>
    </td>
    <td>{props.portfolio.created}</td>
    <td>
      <Link to={"/edit/" + props.portfolio._id}>Edit</Link>
      <br />
      <Link to={"/delete/" + props.portfolio._id}>Delete</Link>
    </td>
  </tr>
);

class PortfolioView extends Component {
  constructor(props) {
    super(props);
    this.state = { portfolios: [] };
  }

  getImage(img) {
    let { state } = this;
    storage
      .child(`${img}.png`)
      .getDownloadURL()
      .then(url => {
        state[img] = url;
        this.setState(state);
      })
      .catch(error => {
        // Handle any errors
      });
  }

  componentDidMount() {
    axios
      .get("https://scc-api-portfolio.now.sh/portfolios")
      .then(response => {
        this.setState({ portfolios: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  portfolioView() {
    return this.state.portfolios.map(function(currentPortfolio, i) {
      return <Portfolio portfolio={currentPortfolio} key={i} />;
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Portfolioklienter</h3>
          <Table dark responsive style={{ marginTop: 20 }}>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">URL</th>
                <th scope="col">Created</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>{this.portfolioView()}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default PortfolioView;
