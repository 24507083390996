import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

const PortfolioGrids = lazy(() => import("./portfolio.jsx"));
class PortfolioGrid extends React.Component {
  render() {
    return (
      <div>
        <Suspense fallback={<h1 className="stray-cat">Still Loading…</h1>}>
          <PortfolioGrids />
        </Suspense>
      </div>
    );
  }
}

export default PortfolioGrid;
