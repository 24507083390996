import React, { useContext } from "react";
import "../styles/menu.scss";
import routes from "../routes";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import PortfolioList from "./portfolio.jsx";
import PortfolioEdit from "./portfolioEdit";
import { AuthContext } from "../index";

const SlideMenu = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Menu right disableAutoFocus>
      {routes.map((route, i) => (
        <Link key={i} to={route.path}>
          {route.name}
        </Link>
      ))}
      {isLoggedIn && <Link to="/admin">Admin</Link>}
      {/*  <Link to="/" className="menu-item">
            Home
          </Link>
         <Link to="/portfolio" className="menu-item">
            Portfolio
          </Link>
          <Link to="/about" className="menu-item">
            About
    </Link>*/}
      <Route path="/edit/:id" component={PortfolioEdit} />
    </Menu>
    /*<Route path="/portfolio" component={PortfolioList} />*/
  );
};

export default SlideMenu;
