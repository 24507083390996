import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";

export default class PortfolioEdit extends Component {
  constructor(props) {
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeImg = this.onChangeImg.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      name: "",
      url: "",
      img: ""
    };
  }

  componentDidMount() {
    axios
      .get(
        "https://scc-api-portfolio.now.sh/portfolios/" +
          this.props.match.params.id
      )
      .then(response => {
        this.setState({
          name: response.data.name,
          url: response.data.url,
          img: response.data.img
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeUrl(e) {
    this.setState({
      url: e.target.value
    });
  }

  onChangeImg(e) {
    this.setState({
      img: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const obj = {
      name: this.state.name,
      url: this.state.url,
      img: this.state.img
    };
    console.log(obj);
    axios
      .delete(
        "https://scc-api-portfolio.now.sh/portfolios/delete/" +
          this.props.match.params.id,
        obj
      )
      .then(res => console.log(res.data));

    this.props.history.push("/admin");
  }

  render() {
    return (
      <div className="container">
        <div className="col-md-4 justify-content-center offset-md-4">
          <h2>Do you wish to delete this entry?</h2>
          <img
            className="portfolioLogo"
            src={this.state.img}
            align="center"
            alt="Portfolio logo"
          />
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label>Name: </Label>
              <Input
                type="text"
                className="form-control"
                value={this.state.name}
                onChange={this.onChangeName}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label>URL: </Label>
              <Input
                type="text"
                className="form-control"
                value={this.state.url}
                onChange={this.onChangeUrl}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label>Image: </Label>
              <Input
                type="text"
                className="form-control"
                value={this.state.img}
                onChange={this.onChangeImg}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Button type="submit">Delete portfolio entry</Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}
