import React from "react";
import AdminView from "./AdminView";
import PortfolioEdit from "./components/portfolioEdit";
import PortfolioRemove from "./components/portfolioRemove";

const protectedRoutes = [
  {
    name: "admin",
    exact: true,
    path: "/admin",
    main: props => <AdminView {...props} />,
    public: false
  },
  {
    name: "Edit",
    path: "/edit/:id",
    main: props => <PortfolioEdit {...props} />,
    public: false
  },
  {
    name: "Delete",
    path: "/delete/:id",
    main: props => <PortfolioRemove {...props} />,
    public: false
  }
];

export default protectedRoutes;
