import React, { useState, useContext } from "react";
import { Button, Form, Input } from "reactstrap";
import { AuthContext } from "./index";
import * as firebase from "firebase";
import { withRouter } from "react-router-dom";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrors] = useState("");

  const Auth = useContext(AuthContext);
  const handleForm = e => {
    e.preventDefault();
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(res => {
            if (res.user) Auth.setLoggedIn(true);
            history.push("/admin");
          })
          .catch(e => {
            setErrors(e.message);
          });
      });
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            console.log(result);
            history.push("/admin");
            Auth.setLoggedIn(true);
          })
          .catch(e => setErrors(e.message));
      });
  };
  return (
    <div className="container">
      <div className="col-md-4 justify-content-center offset-md-4">
        <h2>Login</h2>
        <Form onSubmit={e => handleForm(e)}>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            type="email"
            placeholder="email"
          />
          <Input
            onChange={e => setPassword(e.target.value)}
            name="password"
            value={password}
            type="password"
            placeholder="password"
          />
          {/*}
          <button
            onClick={() => signInWithGoogle()}
            className="googleBtn"
            type="button"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              alt="logo"
            />
            Login With Google
  </button>*/}
          <Button type="submit">Login</Button>
          <span>{error}</span>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(Login);
