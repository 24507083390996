import React, { Component } from "react";
import "../styles/footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer className="page-footer font-small blue">
        <div className="footer-copyright text-center py-3">
          {/*アー・ストレイ・キャット・プロダクション*/}
        </div>
      </footer>
    );
  }
}

export default Footer;
